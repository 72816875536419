.snippetPage .editBtn{
  position: absolute;
  top: 0px;
  right: 0px;
}

.showHideToggle{
  display: none !important;
}

.snippetPage .editableElement:hover .showHideToggle {
  display: inline-flex !important;
}


.snippetPage textarea { 
  word-break: break-all;
  width: 100%;

}

.sidebarColumn{
  z-index: 1;
  flex: 0 0 70px;
}

.sidebarColumn li .active{
  border-left: 5px #d914f1 solid;
  margin-left: -5px;
  padding-left: 5px;

}
.sidebarColumn a:hover{
  color:#fff;
}

.logoIcon{
  width: 50px;
  height: 50px;
  top: 10px;
  position: fixed;
  left:7px;
}
.profilePhotoContainer::after{
  display: none !important;
}
.profilePhoto{
  width: 50px;
  height: 50px;
  box-shadow: 0 0 8px black;
  -webkit-box-shadow: 0 0 8px black;
  -moz-box-shadow: 0 0 8px black;
}

.sidebarToggler{
  background-color: #35033b;
  width: 100px;
  height: 100px;
  position: fixed;
  top: -50px;
  left: -50px;
  z-index: 5;
  transform: rotate(45deg);
}

.sidebarTogglerArrow{
  width: 25px;
  height: 25px;
  margin-top: 38px;
  margin-left: 65px;
}
.down-right {
	-webkit-animation: down-right 0.6s ease-in-out both;
	        animation: down-right 0.6s ease-in-out both;
}
.up-left {
	-webkit-animation: up-left 0.6s ease-in-out both;
	        animation: up-left 0.6s ease-in-out both;
}
@-webkit-keyframes down-right {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}
@keyframes down-right {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

@-webkit-keyframes up-left {
  0% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes up-left {
  0% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}




.slide-right {
  	-webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	  animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-left {
  	-webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	  animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}



 @-webkit-keyframes slide-right {
  0% {
    flex: 0 0 0px;
  }
  100% {
    flex: 0 0 70px;
  }
}
@keyframes slide-right {
  0% {
    flex: 0 0 0px;
  }
  100% {
    flex: 0 0 70px;
  }
}
 @-webkit-keyframes slide-left {
  0% {
    flex: 0 0 70px;
  }
  100% {
    flex: 0 0 0px;
  }
}
@keyframes slide-left {
  0% {
    flex: 0 0 70px;
  }
  100% {
    flex: 0 0 0px;
  }
}
 @-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* .navbarWithSearch */

.slide-bottom {
	-webkit-animation: slide-bottom 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-bottom 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-up {
	-webkit-animation: slide-up 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-up 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
 @-webkit-keyframes slide-up {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes slide-up {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}





.minibutton{
  background-color: rgb(7, 255, 7);
  width: 11px;
  height: 11px;
  position: absolute;
  left: 35px;
  top: 41px;
}

.noBackground{
  background-color: #00000000 !important;
}

.loginDropDown .googleLogin{
  background-color:#db3236;
  color:white;
}
.loginDropDown .googleLogin:hover{
  background-color:#b82a2c;
  color:white;
}
.loginDropDown .facebookLogin:hover{
  background-color:#334d86;
  color:white;
}
.loginDropDown .facebookLogin{
  background-color:#3b5998;
  color:white;
}
.loginDropDown .twitterLogin:hover{
  background-color:rgb(50, 146, 219);
  color:white;
}

.loginDropDown .twitterLogin{
  background-color:#38A1F3;
  color:white;
}




.profile-container .profile-header .profile-stats .inlinestats-col:not(:last-child) {
  border-right: 1px solid #eee;
}
.profile-container .profile-header .profile-stats .inlinestats-col {
  padding-top: 15px;
  text-align: center;
  font-family: 'Lucida Sans','trebuchet MS',Arial,Helvetica;
  border-top: 1px solid #eee;
  min-height: 55px;
}
.profile-container .profile-header .profile-stats .stats-col .stats-value {
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 30px;
  font-family: 'Lucida Sans','trebuchet MS',Arial,Helvetica;
}
.pink {
  color: #e75b8d!important;
}
.profile-container .profile-header .profile-stats .stats-col:not(:last-child) {
  border-right: 1px solid #eee;
}
.profile-container .profile-header .profile-stats .stats-col {
  margin: 30px 0;
  text-align: center;
}
.profile-container .profile-header .profile-stats {
  min-height: 175px;
  border-right: 1px solid #eee;
}
.profile-container .profile-header .profile-info .header-information {
  line-height: 23px;
  margin-top: 15px;
  text-align: justify;
}

.btn-palegreen, .btn-palegreen:focus {
  background-color: #a0d468!important;
  border-color: #a0d468;
  color: #fff;
}
.profile-container .profile-header .profile-info .header-fullname {
  font: 21px 'Lucida Sans','trebuchet MS',Arial,Helvetica;
  /*margin-top: 27px;*/
  display: inline-block;
}
.profile-container .profile-header .profile-info {
min-height: 175px;
border-right: 1px solid #eee;
padding-top: 15px;
padding-bottom: 35px;
}
.profile-container .header-avatar {
  width: 125px;
  height: 125px;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
  border: 5px solid #f5f5f5;
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,.15);
  -moz-box-shadow: 0 0 10px rgba(0,0,0,.15);
  box-shadow: 0 0 10px rgba(0,0,0,.15);
}
.profile-container .profile-header {
  min-height: 175px;
  margin: 15px 15px 0;
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.35);
  -moz-box-shadow: 0 1px 2px rgba(0,0,0,.35);
  box-shadow: 0 1px 2px rgba(0,0,0,.35);
  background-color: #fbfbfb;
}	              




.fontSize20{
  font-size: 2rem  !important;
}
.fontSize17{
  font-size: 1.7rem  !important;
}
.fontSize15{
  font-size: 1.5rem  !important;
}

.fontSize13{
  font-size: 1.3rem  !important;
}
.fontSize12{
  font-size: 1.2rem !important;
}
.fontSize11{
  font-size: 1.1rem !important;
}
.fontSize10{
  font-size: 1rem !important;
}
.fontSize09{
  font-size: 0.9rem !important;
}
.fontSize08{
  font-size: 0.8rem !important;
}
.fontSize07{
  font-size: 0.7rem !important;
}

.padding05{
  padding:0.5rem !important;
}

.noBullet{
  list-style-type: none;
}

.sidebar{
  background-color: #35033b
}

.fullHeightPage{
  height: 100vh
}
a, a:visited,.a:hover,.a:active {
  text-decoration:none !important;
}
/* Searchbar */

#custom-search-input{
  border: solid 1px #E4E4E4;
  border-radius: 6px;
  background-color: #fff;
}

#custom-search-input input{
  border: 0;
  box-shadow: none;
}

#custom-search-input button{
  margin: 7px 0px;
  background: none;
  box-shadow: none;
  border: 0;
  padding: 0 8px 0 10px;
  color: #666666;


}
#custom-search-input .searchButton{
  border-left: solid 1px #ccc;
}

#custom-search-input button:hover{
  border: 0;
  box-shadow: none;
  border-left: solid 1px #ccc;
}

#custom-search-input .glyphicon-search{
  font-size: 23px;
}

/* sidebar */
.sidebarMenuItem{
  color:#fff;
  margin: 25px auto;
  font-size: 1.5rem
}

.sidebar ul{
  position: fixed;
  top:80px;
  left:0px;
  padding-left: 7px;
}

.sidebar .NavIcon{
  margin: 0px auto;
  width: 25px;
  height:25px;
}


/* snippetCard */
.snippetCard{
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.11);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.11);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.11);
}
.snippetCardActiveBorder{
  border-left: 6px solid #ff7b00 !important; 

}
.snippetCardDeactiveBorder{
  border-left: 6px solid rgb(119, 119, 119) !important; 

}
.orangeText{
  color : #ff7b00;
}
.bookmarkTagContainer{
  position: absolute;
  right: 0;
  top: -10px;
}
.bookmarkTagContainer span{
  position: absolute;
  font-weight: 600;
}
.bookmarkTagContainer .oneDigit{
  right: 15px;
}
.bookmarkTagContainer .twoDigit{
  right: 12px;
}


.deactiveBookmarkTag{
  color: #adb5bd;
}



.bookmarkAnimation{
	-webkit-animation: change-colorBookmark  0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: change-colorBookmark 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}
.unBookmarkAnimation {
	-webkit-animation: change-colorUnBookmark  0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: change-colorUnBookmark 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}


@-webkit-keyframes change-colorBookmark {
  0% {
    color: #adb5bd ;
  }
  100% {
    color: #007bff;
  }
}
@keyframes change-colorBookmark {
  0% {
    color:#adb5bd;
  }
  100% {
    color:#007bff;

  }
}

@-webkit-keyframes change-colorUnBookmark {
  0% {
    color: #007bff;
  }
  100% {
    color: #adb5bd ;
  }
}
@keyframes change-colorUnBookmark {
  0% {
    color:#007bff;
  }
  100% {
    color:#adb5bd;

  }
}






/* collection page */
.collectionPage .card{
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.11);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.11);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.11);
  border-left: 4px solid #ff7b00 !important; 
}

.badgeContainer{
  position: absolute;
    top: 5px;
    right: 5px;
}

.keywordPill{
  line-height: 1;
  border-width: 2px;

}
.pointer{
  cursor: pointer;
}

.RichEditor-root {
    background: #fff;
    border: 1px solid #ddd;
    font-family: 'Georgia', serif;
    font-size: 14px;
    padding: 15px;
  }
  
  .RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }
  
  .RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
  }
  
  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }
  
  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }
  
  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }
  
  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
  }
  
  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }
  
  .RichEditor-activeButton {
    color: #5890ff;
  }
